import { createGlobalStyle } from "styled-components"
import modernNormalize from "styled-modern-normalize"

const GlobalStyle = createGlobalStyle`
    
    ${modernNormalize}
    * {
        box-sizing: border-box;
    }
    body {
        background-color: ${({ theme }) => theme.colors.secondary};
        color: ${({ theme }) => theme.colors.primary};
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-family: ${({ theme }) => theme.fonts.primary};
        line-height: 1.5rem;
        font-weight: 400;
        text-rendering: optimizeLegibility;
    }
    a {
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: 400;
        line-height: ${({ theme }) => theme.lineHeightBase};
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: 0;
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
            font-size: ${({ theme }) => theme.fontSizes.sm};
        }
        @media (max-width: ${props => props.theme.breakpoints.sm}) {
            font-size: ${({ theme }) => theme.fontSizes.xs};
        }
    }
    h1 {
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSizes.xl};
        line-height: ${({ theme }) => theme.lineHeightBase};
        @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
            font-size: 2.625rem;
            line-height: 4rem;
        }
    }
    h2 {
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSizes.xl};
        line-height: ${({ theme }) => theme.lineHeightLarge};
        margin: ${({ theme }) => theme.spacingBase} 0rem;
        padding-bottom: 0.4rem; 
        @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
            font-size: 3.5rem;
            margin: 2rem 0rem;
            padding-bottom: 0rem;
            line-height: 1.2;
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: 3rem;
        }
        @media (max-width: ${props => props.theme.breakpoints.sm}) {
            font-size: 2rem;
            margin: ${({ theme }) => theme.spacingBaseMobile} 0rem;
        }
    }
    h3 {
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSizes.lg};
        line-height: ${({ theme }) => theme.lineHeightLarge};
        margin: ${({ theme }) => theme.spacingBase} 0rem;
        @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
            font-size: 2.5rem;
            line-height: 1.1;
            margin: 2rem 0rem;
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: 2rem;
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 1.3rem;
            margin: 1.5rem 0rem;
            line-height: 1.3;
        }
    }
    h4 {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: ${({ theme }) => theme.lineHeightBase};
        margin: ${({ theme }) => theme.spacingBase} 0rem;
        display: inline;
        padding-bottom: .25rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
        @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
            font-size: ${({ theme }) => theme.fontSizes.sm};
            margin: 2rem 0rem;
        }
        @media (max-width: ${props => props.theme.breakpoints.sm}) {
            font-size: ${({ theme }) => theme.fontSizes.xs};
            margin: ${({ theme }) => theme.spacingBaseMobile} 0rem;
        }
    }
    p {
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: ${({ theme }) => theme.lineHeightBase};
        margin: ${({ theme }) => theme.spacingBase} 0rem;
        @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
            font-size: ${({ theme }) => theme.fontSizes.sm};
            margin: 2rem 0rem;
        }
        @media (max-width: ${props => props.theme.breakpoints.sm}) {
            font-size: ${({ theme }) => theme.fontSizes.xs};
            margin: ${({ theme }) => theme.spacingBaseMobile} 0rem; 
        }
    }
    .w-half {
        width: 50%;

        @media (max-width: ${props => props.theme.breakpoints.sm}) {
            width: 100%;
        }
    }
`

export default GlobalStyle
