import * as React from "react";
import { motion, useCycle } from "framer-motion";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import styled from "styled-components";

const StyledBackground = styled(motion.div)`
    width: 240px;
    height: 100vh;
    background: rgba(105, 182, 80, 0.95);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    border-radius: 20px 0px 0px 20px;
`

const sidebar = {
  open: {
      width: '240px'
  },
  closed: {
    width: 0
  }
};

const background = {
    open: {
      opacity: 1
    },
    closed: {
      opacity: 0
    }
};

export default function MobileMenu() {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
    >
      <StyledBackground variants={sidebar}> 
        <Navigation />
      </StyledBackground>
      <motion.div
        css={`
            width: 100vw;
            height: 100vh;
            background: rgba(25, 25, 25, 0.75);
            position: fixed;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: 10;
        `}
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={background} 
      />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};
