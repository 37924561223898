import * as React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const StyledButton = styled.button`
    z-index: 12;
    position: relative;
    outline: none;
    border: none;
    border-radius: 100%;
    appearance: none;
    background: ${props => props.theme.colors.primary};
    padding: 0.75rem 0.68rem 0.55rem 0.75rem;
    margin: 0;
    margin-top: -2px;
    line-height: 0;
` 

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => (
  <StyledButton onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </StyledButton>
);
