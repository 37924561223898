import * as React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Button from "./Button";

const StyledMenuLink = styled(Button)`
    font-size: 1.2rem;
    color: ${ props => props.theme.colors.secondary };
    background: transparent;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
`

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const itemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

export const Navigation = () => (
    <motion.ul css={`margin-top: 6rem; list-style: none;`} variants={variants}>
        <motion.li
        variants={itemVariants}
        >
            <StyledMenuLink internalLink="/ueber-uns/">Über uns</StyledMenuLink>
        </motion.li>
        <motion.li
        variants={itemVariants}
        >
            <StyledMenuLink internalLink="/leistungen/">Leistungen</StyledMenuLink>
        </motion.li>
        <motion.li
        variants={itemVariants}
        >
            <StyledMenuLink internalLink="/referenzen/">Referenzen</StyledMenuLink>
        </motion.li>
        <motion.li
        variants={itemVariants}
        >
            <StyledMenuLink internalLink="/kontakt/">Kontakt</StyledMenuLink> 
        </motion.li>
    </motion.ul>
);


            
            