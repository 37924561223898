import React from "react"
import { Link } from "gatsby"

import styled, { css } from "styled-components"

export const StyledButton = styled.a`
  font-size: ${props => props.theme.fontSizes.base};
  padding: .6rem 2rem .7rem 2rem;
  border-radius: 50px;
  margin: 1rem 0rem ${props => props.theme.spacingBase} 0rem;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
  position: relative;

  &:last-of-type {
    margin-right: 0rem;
  }

  i.arrow-down {
    border: solid ${props => props.theme.colors.secondary};
    border-width: 0 2px 2px 0;
    display: inline-block;
    margin-bottom: .1rem;
    margin-left: .4rem;
    padding: .4rem;
    transform: rotate(-45deg);
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-bottom: 0rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: .36rem;
      margin-bottom: .1rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: .25rem;
      margin-bottom: 0rem;
      margin-left: .3rem;
    }
  }

  ${props => props.primary &&
    css`
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.secondary};

      i.arrow-down {
        display: inline-block;
      }
    `}

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: ${props => props.theme.fontSizes.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${props => props.theme.fontSizes.xs};
    margin: 0.5rem 0rem ${props => props.theme.spacingBase} 0rem;
  }
`

export default function Button(props) {  
  return (
        <StyledButton
          className={props.className}
          as={props.internalLink ? Link : `a`}
          to={props.internalLink}
          href={props.externalLink}
          primary={props.primary}
        >
          {props.children}
          <i className="arrow-down" />
        </StyledButton>
  )
}
