import React from 'react'
import Logo from '../styles/Logo'
import Button from '../styles/Button'
import styled from 'styled-components'
import { Link } from 'gatsby'
import MobileMenu from '../styles/MobileMenu'

import { useMediaQuery } from 'beautiful-react-hooks'; 

const StyledHeader = styled.header`
    padding: 3.5rem ${props => props.theme.spacingXLarge};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 20;
    position: relative;

    @media (max-width: ${props => props.theme.breakpoints.xl}) {
        padding: 3.5rem ${props => props.theme.spacingXBase};

        .logo {
            width: 380px;
            height: auto;
        }
    }

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
        .logo {
            width: 320px;
            height: auto;
        }
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
        padding: 3.5rem ${props => props.theme.spacingXsm};

        .logo {
            width: 280px;
            height: auto;
        }
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
        padding: 2rem ${props => props.theme.spacingXxs};

        .logo {
            width: 240px;
            height: auto;
        }
    }

    a {
        line-height: 1;
    }

    .menu {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`

const StyledHeaderButton = styled(Button)`
    background: transparent;
    margin: 0;
    margin-right: 3.75rem;
    padding: 0rem;
    color: ${props => props.theme.colors.primary};

    @media (max-width: ${props => props.theme.breakpoints.xl}) {
        margin-right: 2.5rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
        margin-right: 1.5rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
        display: none;
    }
`

export const TabletMenu = () => (
    <>
        <StyledHeaderButton internalLink="/ueber-uns/">Über uns</StyledHeaderButton>
        <StyledHeaderButton internalLink="/leistungen/">Leistungen</StyledHeaderButton>
        <StyledHeaderButton internalLink="/referenzen/">Referenzen</StyledHeaderButton>
        <StyledHeaderButton internalLink="/kontakt/">Kontakt</StyledHeaderButton> 
    </>
)

export default function Header() {
    const isMobile = useMediaQuery('(max-width: 860px)');

    return (
        <StyledHeader>
            <div>
                <Link to="/" aria-label="Logo zur Startseite">
                    <Logo></Logo>
                </Link>
            </div>
            <div className="menu">
                { isMobile
                    ? <MobileMenu></MobileMenu>
                    : <TabletMenu></TabletMenu>
                }       
            </div>
        </StyledHeader>
    )
}
