import React from "react"

import styled, { ThemeProvider } from "styled-components"
import Theme from "../styles/Theme"
import GlobalStyles from "../styles/GlobalStyles"
import Header from "./header"

const StyledLayout = styled.div``

export default function Layout({ children, location }) {
  return (
    <ThemeProvider theme={Theme}>
      <StyledLayout>
        <GlobalStyles />
        {
          location ? null : <Header></Header>
        }
        <main>{children}</main>
      </StyledLayout>
    </ThemeProvider>
  )
}
