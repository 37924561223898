module.exports = {
  colors: {
    primary: "#69B650",
    secondary: "#1D2421",
    tertiary: "#ffffff",
  },
  breakpoints: {
    xs: "500px",
    sm: "860px",
    md: "1000px",
    lg: "1350px",
    xl: "1520px"
  },
  fonts: {
    primary: "IBM Plex Sans",
  },
  fontSizes: {
    xs: "1rem",
    sm: "1.4rem",
    base: "1.75rem",
    lg: "3.125rem",
    xl: "5rem",
  },
  pageWidth: "86rem",
  lineHeightBase: "1.6",
  lineHeightLarge: "1.1",
  spacingXxs: "1.5rem",
  spacingXsm: "2.5rem",
  spacingXBase: "4rem",
  spacingXLarge: "8rem",
  spacingBaseMobile: "1.5rem",
  spacingBase: "2.5rem",
  spacingMobile: "5rem",
  spacingLarge: "7rem",
}
